import React from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';
import Image from 'components/common/utils/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Layout } from 'components/common';
import Section from 'components/common/Section';
import FolderSvg from 'images/icons/folder.svg';
import { format } from 'date-fns';
import { publishedArticles } from './articles';
import './styles.sass';
import { Button } from 'react-bootstrap';

const Press = () => (
  <Layout showBars>
    <CharlesMeta
      data={{
        title: 'Ils parlent de Mia.co | Dossier de Presse | Contact',
        description: 'Mia.co 1ère plateforme dédiée à la santé sexuelle féminine. Téléconsultez une sexologue de manière confidentielle par vidéo, téléphone ou messagerie sécurisée.',
      }}
    />
    <div>
      <Section className="mb-4 mt-4 mt-lg-0">
        <Container>
          <h1 className="section-title text-center kit-media-h1">
            Espace presse et médias
          </h1>
          <div className="kit-media-div">
            <h2 className="kit-media-text text-center">
              <p>
                Contact :
                <a href="mailto:camille@mia.co"> camille@mia.co</a>
              </p>
              <span>Pour accéder au kit média, cliquez sur le dossier</span>
              <br />
              <div className="d-flex justify-content-center mt-4">
                <a
                  href="https://drive.google.com/drive/folders/1pWLuuQhIMYLg7SAr1byCr8OhDKG0BUPM?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline-hover"
                >
                  <Image filename="icons/folder.svg" className="press-folder" />
                </a>
              </div>
            </h2>
          </div>
        </Container>
      </Section>
      {/* <Section className="lc-white pt-5 pr-1 pl-1 pt-sm-5 pr-sm-5 pl-sm-5 pb-0">
        <Container className="text-center">
          <Row className="justify-content-center mt-5 text-left">
            <Col md={12}>
              {publishedArticles.map(
                (
                  {
                    brandImage,
                    media,
                    title,
                    location,
                    publicationDate,
                  },
                ) => (
                  <a
                    href={location}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    key={publishedArticles.id}
                    className="press-list-single-article"
                  >
                    <Row className="mb-4 align-items-center">
                      <Col xs={3} sm={2}>
                        <Image
                          filename={brandImage}
                          alt={media}
                          className="img-fluid"
                        />
                      </Col>
                      <Col>
                        <h3 className="mt-0 mb-1 h6">
                          {title}
                        </h3>
                        <span className="small">
                          {format(
                            new Date(
                              publicationDate,
                            ),
                            'DD-MM-YYYY',
                          )}
                        </span>
                      </Col>
                    </Row>
                  </a>
                ),
              )}
            </Col>
          </Row>
        </Container>
      </Section> */}
    </div>
  </Layout>
);

export default Press;
